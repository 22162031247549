<template>
  <div v-for="error in errors" :key="error">{{ error }}</div>
</template>

<script>
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      email: this.$route.params.email,
      messages: [],
      errors: [],
    };
  },
  methods: {
    confirmEmail() {
      this.store
        .dispatch("User/UserActivateAccount", { email: this.email })
        .then((result) => {
          console.log(result);
          this.$router.push({
            name: "Login",
          });
        })
        .catch((err) => {
          console.log(err);
          this.errors = err.messages;
        });
    },
  },
  mounted() {
    this.confirmEmail();
  },
};
</script>

<style>
</style>